export const appHead = {"meta":[{"name":"viewport","content":"width=device-width,initial-scale=1,viewport-fit=cover"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[{"src":"https://consent.cookiefirst.com/banner.js?cookiefirst-key=25044931-e8ef-4d3b-ab35-e4dc73124f41","async":true}],"noscript":[],"title":"Horeca groothandel - Bidfood (voorheen Deli XL)","bodyAttrs":{"class":"overflow-x-hidden"},"charset":"utf-8","viewport":"width=device-width,initial-scale=1,viewport-fit=cover"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null