import { default as healthnRtkkZoDN4Meta } from "/opt/atlassian/pipelines/agent/build/apps/portal/pages/health.vue?macro=true";
import { default as contact_45preferenceP6daZUa0VsMeta } from "/opt/atlassian/pipelines/agent/build/apps/portal/pages/portal/contact-preference.vue?macro=true";
import { default as customer_45serviceLhjWHJ4fI3Meta } from "/opt/atlassian/pipelines/agent/build/apps/portal/pages/portal/customer-service.vue?macro=true";
import { default as forbiddenytf9L8Z8IHMeta } from "/opt/atlassian/pipelines/agent/build/apps/portal/pages/portal/forbidden.vue?macro=true";
import { default as insightsZoJFlMik2aMeta } from "/opt/atlassian/pipelines/agent/build/apps/portal/pages/portal/insights.vue?macro=true";
import { default as invoicesVutcAE0mpSMeta } from "/opt/atlassian/pipelines/agent/build/apps/portal/pages/portal/invoices.vue?macro=true";
import { default as ordersn0BwE9g8SDMeta } from "/opt/atlassian/pipelines/agent/build/apps/portal/pages/portal/orders.vue?macro=true";
import { default as personal_45informationhURQzuVvBDMeta } from "/opt/atlassian/pipelines/agent/build/apps/portal/pages/portal/personal-information.vue?macro=true";
import { default as users_45and_45locationsrXh4K0l6MbMeta } from "/opt/atlassian/pipelines/agent/build/apps/portal/pages/portal/users-and-locations.vue?macro=true";
export default [
  {
    name: healthnRtkkZoDN4Meta?.name ?? "health___nl",
    path: healthnRtkkZoDN4Meta?.path ?? "/health",
    meta: healthnRtkkZoDN4Meta || {},
    alias: healthnRtkkZoDN4Meta?.alias || [],
    redirect: healthnRtkkZoDN4Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/portal/pages/health.vue").then(m => m.default || m)
  },
  {
    name: contact_45preferenceP6daZUa0VsMeta?.name ?? "portal-contact-preference___nl",
    path: contact_45preferenceP6daZUa0VsMeta?.path ?? "/portal/contact-preference",
    meta: contact_45preferenceP6daZUa0VsMeta || {},
    alias: contact_45preferenceP6daZUa0VsMeta?.alias || [],
    redirect: contact_45preferenceP6daZUa0VsMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/portal/pages/portal/contact-preference.vue").then(m => m.default || m)
  },
  {
    name: customer_45serviceLhjWHJ4fI3Meta?.name ?? "portal-customer-service___nl",
    path: customer_45serviceLhjWHJ4fI3Meta?.path ?? "/portal/customer-service",
    meta: customer_45serviceLhjWHJ4fI3Meta || {},
    alias: customer_45serviceLhjWHJ4fI3Meta?.alias || [],
    redirect: customer_45serviceLhjWHJ4fI3Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/portal/pages/portal/customer-service.vue").then(m => m.default || m)
  },
  {
    name: forbiddenytf9L8Z8IHMeta?.name ?? "portal-forbidden___nl",
    path: forbiddenytf9L8Z8IHMeta?.path ?? "/portal/forbidden",
    meta: forbiddenytf9L8Z8IHMeta || {},
    alias: forbiddenytf9L8Z8IHMeta?.alias || [],
    redirect: forbiddenytf9L8Z8IHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/portal/pages/portal/forbidden.vue").then(m => m.default || m)
  },
  {
    name: insightsZoJFlMik2aMeta?.name ?? "portal-insights___nl",
    path: insightsZoJFlMik2aMeta?.path ?? "/portal/insights",
    meta: insightsZoJFlMik2aMeta || {},
    alias: insightsZoJFlMik2aMeta?.alias || [],
    redirect: insightsZoJFlMik2aMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/portal/pages/portal/insights.vue").then(m => m.default || m)
  },
  {
    name: invoicesVutcAE0mpSMeta?.name ?? "portal-invoices___nl",
    path: invoicesVutcAE0mpSMeta?.path ?? "/portal/invoices",
    meta: invoicesVutcAE0mpSMeta || {},
    alias: invoicesVutcAE0mpSMeta?.alias || [],
    redirect: invoicesVutcAE0mpSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/portal/pages/portal/invoices.vue").then(m => m.default || m)
  },
  {
    name: ordersn0BwE9g8SDMeta?.name ?? "portal-orders___nl",
    path: ordersn0BwE9g8SDMeta?.path ?? "/portal/orders",
    meta: ordersn0BwE9g8SDMeta || {},
    alias: ordersn0BwE9g8SDMeta?.alias || [],
    redirect: ordersn0BwE9g8SDMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/portal/pages/portal/orders.vue").then(m => m.default || m)
  },
  {
    name: personal_45informationhURQzuVvBDMeta?.name ?? "portal-personal-information___nl",
    path: personal_45informationhURQzuVvBDMeta?.path ?? "/portal/personal-information",
    meta: personal_45informationhURQzuVvBDMeta || {},
    alias: personal_45informationhURQzuVvBDMeta?.alias || [],
    redirect: personal_45informationhURQzuVvBDMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/portal/pages/portal/personal-information.vue").then(m => m.default || m)
  },
  {
    name: users_45and_45locationsrXh4K0l6MbMeta?.name ?? "portal-users-and-locations___nl",
    path: users_45and_45locationsrXh4K0l6MbMeta?.path ?? "/portal/users-and-locations",
    meta: users_45and_45locationsrXh4K0l6MbMeta || {},
    alias: users_45and_45locationsrXh4K0l6MbMeta?.alias || [],
    redirect: users_45and_45locationsrXh4K0l6MbMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/portal/pages/portal/users-and-locations.vue").then(m => m.default || m)
  }
]