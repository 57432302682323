import revive_payload_client_vyuz6bt18N from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.6.2_@types+node@18.11.18_typescript@5.3.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_sMNNCKrS5c from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.6.2_@types+node@18.11.18_typescript@5.3.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/apps/portal/.nuxt/components.plugin.mjs";
import unhead_GSmPY02AJx from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.6.2_@types+node@18.11.18_typescript@5.3.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_9CwnpPoeJz from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.6.2_@types+node@18.11.18_typescript@5.3.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_5qEmTnqLrM from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt-cookie-first@0.1.3/node_modules/nuxt-cookie-first/dist/runtime/plugin.mjs";
import composition_FBNRJshUZf from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.9_vue@3.4.37/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_tMaiYEnm4o from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.9_vue@3.4.37/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_2KhHqfgtPY from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.6.2_@types+node@18.11.18_typescript@5.3.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import logger_client_NoN5FB4OWs from "/opt/atlassian/pipelines/agent/build/apps/portal/plugins/logger.client.ts";
import onGraphQLError_NRXscsGAPF from "/opt/atlassian/pipelines/agent/build/apps/portal/plugins/onGraphQLError.ts";
import vue_gtm_client_Cm24Dsc5BN from "/opt/atlassian/pipelines/agent/build/apps/portal/plugins/vue-gtm.client.js";
export default [
  revive_payload_client_vyuz6bt18N,
  router_sMNNCKrS5c,
  components_plugin_KR1HBZs4kY,
  unhead_GSmPY02AJx,
  prefetch_client_9CwnpPoeJz,
  plugin_5qEmTnqLrM,
  composition_FBNRJshUZf,
  i18n_tMaiYEnm4o,
  chunk_reload_client_2KhHqfgtPY,
  logger_client_NoN5FB4OWs,
  onGraphQLError_NRXscsGAPF,
  vue_gtm_client_Cm24Dsc5BN
]