import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a07dfdd"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  src: "https://www.bidfood.nl/errors/503-delixl-cms.htm",
  title: "Bidfood Error Page"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("iframe", _hoisted_1))
}