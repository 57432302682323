export default {
  "common": {
    "error_modal": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets misgegaan"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer het opnieuw of neem contact op met de klantenservice"])},
      "button": {
        "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar klantenservice"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleer"])}
      }
    },
    "alert": {
      "customer_service": {
        "button": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantenservice"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/portal/customer-service"])}
        }
      }
    },
    "days": {
      "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ma"])},
      "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di"])},
      "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wo"])},
      "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do"])},
      "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vr"])},
      "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["za"])},
      "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zo"])}
    },
    "full_days": {
      "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maandag"])},
      "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dinsdag"])},
      "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["woensdag"])},
      "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["donderdag"])},
      "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vrijdag"])},
      "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zaterdag"])},
      "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zondag"])}
    }
  },
  "cp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactvoorkeuren"])},
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactvoorkeuren"])},
    "locationPreferenceHeading": {
      "singleLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie contactvoorkeuren"])},
      "multipleLocation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["De contactvoorkeuren van ", _interpolate(_named("location"))])}
    },
    "personalPreferenceHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoonlijke contactvoorkeuren"])},
    "locationPreferenceAlert": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie contactvoorkeuren gelden per locatie."])},
      "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere locatie kiezen"])}
    },
    "personalPreferenceAlert": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoonlijke contactvoorkeuren gelden voor alle locaties."])}
    },
    "documents": {
      "form_field_error_alert_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je e-mailadres lijkt niet te kloppen. Controleer deze en probeer het opnieuw."])},
      "email_required_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimaal 1 e-mailadres vereist"])},
      "email_error_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul een geldig e-mailadres in, alstublieft."])},
      "place_holder_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
      "label_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
      "disabled_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moment..."])},
      "add_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg toe"])},
      "cancel_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
      "save_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigingen opslaan"])},
      "add_email_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres toevoegen"])},
      "add_email_success_alert_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres toegevoegd"])},
      "update_email_success_alert_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres gewijzigd"])},
      "delete_email_success_alert_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres verwijderd"])},
      "invoice": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stuur facturen voor ", _interpolate(_named("location")), " naar:"])},
        "disabledAlertMessage": {
          "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wil je voor facturen contactvoorkeuren zelf instellen? Neem contact op met de klantenservice en we regelen het voor je. Wel zo makkelijk."])},
          "secondary": null,
          "customer_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cp_invoice_not_enabled"])}
        }
      },
      "packingSlipTransportDocument": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stuur pakbonnen voor ", _interpolate(_named("location")), " naar:"])},
        "disabledAlertMessage": {
          "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wil je voor pakbonnen contactvoorkeuren zelf instellen? Neem contact op met de klantenservice en we regelen het voor je. Wel zo makkelijk."])},
          "secondary": null,
          "customer_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cp_pakbon_not_enabled"])}
        }
      },
      "waybillTransportDocument": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stuur vrachtbrieven voor ", _interpolate(_named("location")), " naar:"])},
        "disabledAlertMessage": {
          "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wil je voor vrachtbrieven contactvoorkeuren zelf instellen? Neem contact op met de klantenservice en we regelen het voor je. Wel zo makkelijk."])},
          "secondary": null,
          "customer_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cp_vrachtbrief_not_enabled"])}
        }
      },
      "returnSlipTransportDocument": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stuur retourbonnen voor ", _interpolate(_named("location")), " naar:"])},
        "disabledAlertMessage": {
          "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wil je voor retourbonnen contactvoorkeuren zelf instellen? Neem contact op met de klantenservice en we regelen het voor je. Wel zo makkelijk."])},
          "secondary": null,
          "customer_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cp_retourbon_not_enabled"])}
        }
      },
      "pickingCorrection": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stuur pick-correcties voor ", _interpolate(_named("location")), " naar:"])},
        "disabledAlertMessage": {
          "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wil je voor pick-correcties contactvoorkeuren zelf instellen? Neem contact op met de klantenservice en we regelen het voor je. Wel zo makkelijk."])},
          "secondary": null,
          "customer_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cp_pickcorrectie_not_enabled"])}
        }
      },
      "articlesOverview": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stuur artikeloverzichten voor ", _interpolate(_named("location")), " naar:"])},
        "disabledAlertMessage": {
          "primary": null,
          "secondary": null
        }
      }
    }
  },
  "personal_information": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoonlijke gegevens"])},
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoonlijke gegevens"])},
    "contact_details": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam en contactgegevens"])},
      "button": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigen"])}
      },
      "fields": {
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])}
        },
        "email": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])}
        },
        "phone": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiel nummer"])}
        }
      }
    },
    "login_details": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login gegevens"])},
      "button": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigen"])}
      },
      "fields": {
        "username": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam"])}
        },
        "password": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])}
        }
      }
    },
    "role_details": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol"])},
      "alert": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol wijzigen?  <br/> Neem contact op met de klantenservice."])}
      }
    },
    "location_details": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locaties"])},
      "toggle": {
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " locaties"])}
      },
      "alert": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie wijzigen? <br/> Neem contact op met de klantenservice."])}
      }
    },
    "detailsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam en contactgegevens"])},
    "formField": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
      "middleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tussenvoegsel"])},
      "middleNameSubText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optioneel"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiel nummer"])},
      "mobileSubText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiele nummer met landcode +31. Bijvoorbeeld +31612345678"])},
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigingen opslaan"])},
      "alertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigingen opgeslagen"])},
      "error": {
        "message": {
          "common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helaas, sommige gegevens zijn niet goed ingevuld. Controleer de velden en pas deze aan."])},
          "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul uw voornaam in, alstublieft."])},
          "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul uw achternaam in, alstublieft."])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul een geldig e-mailadres in, alstublieft."])},
          "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer uw volledige internationale mobiele nummer in, beginnend met +31. Bijvoorbeeld: +31612345678."])}
        }
      }
    },
    "mobile": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invullen"])}
    }
  },
  "customer_service": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantenservice"])},
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantenservice"])},
    "channelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heb je een andere vraag?"])},
    "textHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We helpen je graag verder tijdens kantooruren."])},
    "alert_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets misgegaan bij het laden van alle contactopties. U kunt ons bellen op <a href='tel:// 088 8008 900' class='link-style'>088 8008 900</a> (tijdens kantooruren)."])},
    "faqText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veelgestelde vragen"])},
    "chatChannel": {
      "channelMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start een gesprek, reactie tijdens kantooruren."])},
      "slide_over": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang tot chat"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer je mobiele nummer in om de chatfunctie te gebruiken. Je mobiele nummer wordt voor je opgeslagen onder Persoonlijke gegevens."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiel nummer"])},
        "technical_error_alert_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het opslaan van het telefoonnummer is niet gelukt. Probeer het nog een keer of voeg het telefoonnummer toe bij de Persoonlijke gegevens."])},
        "invalid_error_alert_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sommige gegevens zijn niet correct ingevuld. Controleer de velden en pas deze aan."])},
        "sub_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiele nummer met landcode +31. Bijvoorbeeld +31612345678"])},
        "button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
        "phone_number_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer uw volledige internationale mobiele nummer in, beginnend met +31. Bijvoorbeeld: +31612345678."])}
      },
      "channelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat direct in Mijn Bidfood <br/> Nu geopend"])}
    },
    "whatsAppChannel": {
      "channelMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start een gesprek, reactie tijdens <br/> kantooruren."])},
      "channelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat direct in Whatsapp Nu geopend"])}
    },
    "phoneChannel": {
      "channelMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bel ons tijdens kantooruren."])},
      "channelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korte wachttijd mogelijk Nu geopend"])}
    },
    "emailChannel": {
      "channelMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stuur een e-mail."])},
      "channelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stuur een e-mail."])}
    }
  },
  "invoice": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturen"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturen"])},
    "empty_state": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen facturen gevonden"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer een andere periode of zoekopdracht. Hulp nodig? Neem contact op met de klantenservice."])},
      "payment_filter_not_found_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer een andere periode, bestelstatus of zoekopdracht. Hulp nodig? Neem contact op met de klantenservice."])},
      "button": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar klantenservice"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/portal/customer-service"])}
      }
    },
    "not_enabled": {
      "empty_state": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen facturen beschikbaar"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wil je jouw facturen bekijken? Neem contact op met de klantenservice en we regelen het voor je. Wel zo makkelijk."])},
        "button": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar klantenservice"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/portal/customer-service"])}
        }
      }
    },
    "search": {
      "page_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturen zoekresultaten"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek een factuur"])},
      "go_back_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle facturen"])},
      "results": {
        "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gezocht op ‘", _interpolate(_named("query")), "’"])},
        "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), "  resultaat"]), _normalize([_interpolate(_named("count")), " resultaten"])])}
      }
    },
    "cards": {
      "button": {
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download factuur (pdf)"])}
      }
    },
    "modals": {
      "download_failed": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloaden mislukt"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets misgegaan met het aanroepen van deze service. Probeer het later opnieuw, of neem contact op met onze klantenservice"])},
        "button": {
          "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar klantenservice"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])}
        }
      }
    }
  },
  "users_and_locations": {
    "page_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers en locaties"])},
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers en locaties"])},
    "alert": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers aanpassen of toevoegen? Neem contact op met de klantenservice."])},
      "not_ready_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers aanpassen of toevoegen? Neem contact op met de klantenservice."])}
    },
    "users": {
      "page_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])},
      "tab": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])}
      },
      "badge": {
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet actief"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])}
      },
      "dropdown_filter": {
        "roles": {
          "option": {
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle rollen"])},
            "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
            "purchaser_plus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchaser +"])},
            "purchaser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchaser"])},
            "administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administratief"])}
          }
        }
      },
      "table_card": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])},
        "button": {
          "locations": {
            "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " locatie"]), _normalize([_interpolate(_named("count")), " locaties"])])}
          }
        }
      },
      "empty_state": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gebruikers gevonden"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen resultaten gevonden. Probeer andere filters of neem contact op met de klantenservice."])},
        "button": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar klantenservice"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/portal/customer-service"])}
        }
      },
      "search": {
        "page_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers zoekresultaten"])},
        "place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek op naam of e-mail"])},
        "all_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle gebruikers"])},
        "search_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gezocht op"])},
        "search_results_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " resultaat"]), _normalize([_interpolate(_named("count")), " resultaten"])])}
      },
      "empty_message": {
        "button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar klantenservice"])}
      },
      "slide_over": {
        "user_role_details": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rol voor ", _interpolate(_named("fullName"))])},
          "permissions": {
            "download_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturen downloaden *"])},
            "order_purchase_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellen vanuit bestellijst"])},
            "order_webshop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellen vanuit webshop"])},
            "manage_order_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellijsten beheren"])}
          },
          "current_role_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidige rol"])},
          "permission_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Indien dit ingesteld is voor de gebruiker."])}
        }
      }
    },
    "locations": {
      "page_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locaties"])},
      "tab": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locaties"])}
      },
      "users_column_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " gebruiker"]), _normalize([_interpolate(_named("count")), " gebruikers"])])},
      "search": {
        "page_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locaties zoekresultaten"])},
        "place_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek op klantnr. (6 karakters)"])},
        "all_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle locaties"])}
      },
      "empty_message": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen locaties gevonden"])},
        "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Geen locaties gevonden voor '", _interpolate(_named("query")), "', vul een volledig klantnummer in van 6 karakters en probeer het opnieuw. Komt u er niet uit? De klantenservice helpt u graag verder."])}
      },
      "slide_over": {
        "location_details": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres en bezorgtijden"])},
          "address": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])}
          },
          "delivery_times": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jouw bezorgtijden *"])},
            "unknown_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezorgtijd onbekend"])},
            "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Bezorgmomenten kunnen op en rond de feestdagen afwijken."])}
          },
          "unknown_delivery_times": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jouw bezorgtijden"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bezorgmomenten zijn (nog) niet bekend."])}
          }
        }
      }
    }
  },
  "forbidden": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen toegang"])},
    "main": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor deze pagina heb je toegang nodig."])},
      "alert": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft een link gevolgd naar een pagina waar u geen toegang (meer) toe heeft. </br> Heeft u hulp nodig? Neem dan contact op met de klantenservice."])}
      }
    }
  },
  "orders": {
    "search": {
      "page_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders zoekresultaten"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek ordernr, referentie"])},
      "results": {
        "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gezocht op ‘", _interpolate(_named("query")), "’"])},
        "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " resultaat"]), _normalize([_interpolate(_named("count")), " resultaten"])])}
      },
      "empty_state": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen orders gevonden"])},
        "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Geen orders gevonden voor  '", _interpolate(_named("query")), "', probeer een andere zoekterm. Kom je er niet uit? De klantenservice helpt je graag verder."])},
        "button": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar klantenservice"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/portal/customer-service"])}
        }
      }
    },
    "upcoming": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders komende levering"])},
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komende leveringen"])},
      "empty_state": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen leveringen gepland"])},
        "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Er zijn geen komende leveringen gepland voor ", _interpolate(_named("location")), ". Ga naar de webshop om te bestellen."])},
        "button": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar webshop"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/webshop"])}
        }
      }
    },
    "history": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orderhistorie"])},
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orderhistorie"])},
      "empty_state": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen orders gevonden"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis je een order? Neem contact op met de klantenservice."])},
        "button": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar klantenservice"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/portal/customer-service"])}
        }
      }
    },
    "on_hold": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders in de wacht"])},
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de wacht"])},
      "alert_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelen in de wacht worden (indien voorradig) geleverd bij de eerstvolgende levering."])},
      "extra_content_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwachte leverdatum"])},
      "reference_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["referentie"])},
      "empty_state": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen artikelen in de wacht"])}
      }
    },
    "slide_overs": {
      "picking_correction": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick-correcties"])},
        "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " artikel (deels) niet leverbaar"]), _normalize([_interpolate(_named("count")), " artikelen (deels) niet leverbaar"])])}
      }
    },
    "delivery_card": {
      "delivery_cost": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bezorgkosten ", _interpolate(_named("cost"))])},
      "time_window": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vandaag"])},
        "with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["tussen ", _interpolate(_named("from")), " en ", _interpolate(_named("till"))])},
        "updated_delivery_time_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwacht bezorgmoment aangepast"])}
      },
      "missing_products": {
        "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " artikel (deels) niet leverbaar"]), _normalize([_interpolate(_named("count")), " artikelen (deels) niet leverbaar"])])}
      },
      "order_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " order in deze levering"]), _normalize([_interpolate(_named("count")), " orders in deze levering"])])}
    }
  },
  "insights": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inzichten"])},
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inzichten"])},
    "card": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO₂ uitstoot leveringen"])},
      "content": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoritten Amsterdam - Parijs"])},
        "total_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dit staat gelijk aan ", _interpolate(_named("totalNumber")), " kg CO₂"])},
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen CO₂ data beschikbaar"])}
      },
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer weten"])}
    },
    "info": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vind je dit interessant?"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We blijven inzichten toevoegen voor jou, zodat je meer inzicht krijgt in jouw aankopen en de mate van duurzaamheid."])}
    },
    "slide_over": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer weten"])},
      "main_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga samen met jouw account manager in gesprek. Hij kan uitleggen hoe je invloed kunt uitoefenen op jouw CO₂ uitstoot bij afleveringen."])},
      "sub_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe kan ik mijn CO₂ uitstoot beïnvloeden?"])},
      "collapsible": {
        "button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe is mijn CO₂ uitstoot berekend?"])},
        "content": {
          "one": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode"])},
            "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze data is berekend over de periode van de laatste 3 afgesloten maanden."])}
          },
          "two": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berekeningsmethode"])},
            "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gepresenteerde CO₂-uitstoot is berekend op basis van boordcomputerdata van het wagenpark van Bidfood. Deze data omvat de afgelegde kilometers en het brandstofverbruik voor de leveringen aan jouw bedrijf (vanaf de voorgaande klant/ locatie tot jouw organisatie) over een periode van drie maanden. De omrekening van brandstofverbruik naar CO₂-uitstoot is uitgevoerd met behulp van de zogenaamde \"Well-to-Wheel\" (WTW) emissiefactor van Emissiefactoren.nl. Deze factor houdt rekening met de CO₂-uitstoot in de gehele keten."])}
          },
          "three": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld Brandstofverbruik"])},
            "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In gevallen waarin de boordcomputer defect was, is er gerekend met het gemiddelde brandstofverbruik van het wagenpark."])}
          },
          "four": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen Rechten"])},
            "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aan de gepresenteerde cijfers kunnen geen rechten worden ontleend. Deze berekening dient slechts ter informatie en heeft geen juridisch bindende werking."])}
          }
        }
      },
      "radio_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja ik wil in gesprek over mijn CO₂-uitstoot"])},
      "save_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
      "save_button_text_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangemeld!"])},
      "cancel_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])},
      "error_alert_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versturen e-mail is niet gelukt. Probeer het later nog een keer."])},
      "success_alert_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registratie opgeslagen."])}
    }
  },
  "purchase_list": {
    "recent": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recente bestellijsten"])}
    },
    "temporary": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdelijke lijsten"])}
    }
  },
  "button": {
    "new_purchase_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe bestellijst"])},
    "all_purchase_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle bestellijsten"])},
    "search_in_purchase_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek in bestellijsten"])},
    "most_bought": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerder gekocht"])}
  },
  "navbar": {
    "menu": {
      "item": {
        "category": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assortiment"])}
        }
      }
    }
  },
  "slideover": {
    "category": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assortiment"])},
      "level2": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alle ", _interpolate(_named("categoryName"))])}
      }
    }
  }
}